/**
 *
 * Modal
 *
 */
import classNames from 'classnames'
import React, { MouseEvent, useCallback, useLayoutEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { ScrollTo } from 'scroll-to-position'
import Button from '../../components/Buttons/Button'
import FlatIcon from '../../components/Icon/FlatIcon'
import { removeProductPathToPathName } from '../../store/products/utils'
import Product, { PRODUCT_FOOTER_ID, PRODUCT_HEADER_ID } from './Product'
import ProductDetailNavigation from './ProductDetailNavigation'

interface IProps {}

export const PRODUCT_DETAIL_MODAL_ID = 'product-detail-modal'

function ProductModal(): JSX.Element {
    const { productId } = useParams<{ productId: string }>()
    const [show, setShow] = useState<boolean>(true)
    const [modalElementRef, setModalElementRef] = useState<HTMLDivElement | null>(null)
    const [showScrollPrev, setShowScrollPrev] = useState<boolean>(false)
    const [showScrollNext, setShowScrollNext] = useState<boolean>(true)
    const [changeTimeoutId, setChangeTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined)

    const history = useHistory()

    const handleOnHide = useCallback(() => {
        setShow(false)
        setTimeout(() => {
            const productsUrl = removeProductPathToPathName(`${window.location.pathname}`)
            const finalUrl = `${productsUrl}${window.location.search}`
            history.push(finalUrl)
        }, 250)
    }, [history, setShow])

    const handlePrevClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()
            const productAttributeElement = document.getElementById(PRODUCT_HEADER_ID)
            if (productAttributeElement) {
                ScrollTo(productAttributeElement, {
                    scrollContainer: modalElementRef!,
                })
            }
        },
        [modalElementRef]
    )

    useLayoutEffect(() => {
        const handleScroll = (evt: Event) => {
            const modal = evt.currentTarget! as HTMLDivElement
            const modalContentElem = modal.querySelector('.modal-content')!
            if (changeTimeoutId) {
                clearTimeout(changeTimeoutId)
            }
            const timeoutId = setTimeout(() => {
                const rect = modalContentElem.getBoundingClientRect()
                const scrollTop = modal.scrollTop
                const scrollHeight = modal.scrollHeight
                const offsetHeight = modal.offsetHeight
                const contentHeight = scrollHeight - offsetHeight
                setShowScrollPrev(rect.y + 200 < 0)
                setShowScrollNext(!(contentHeight <= scrollTop))
            }, 150)
            setChangeTimeoutId(timeoutId)
        }

        if (modalElementRef) {
            modalElementRef.addEventListener('scroll', handleScroll)
        }
        return () => {
            if (modalElementRef) {
                modalElementRef.removeEventListener('scroll', handleScroll)
            }
        }
    }, [modalElementRef, setShowScrollPrev, setShowScrollNext, setChangeTimeoutId, changeTimeoutId])

    const handleOnEntered = useCallback(
        (element: HTMLDivElement) => {
            setModalElementRef(element)
        },
        [setModalElementRef]
    )

    const handleNextClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()
            const productAttributeElement = document.getElementById(PRODUCT_FOOTER_ID)
            if (productAttributeElement) {
                ScrollTo(productAttributeElement, {
                    scrollContainer: modalElementRef!,
                })
            }
        },
        [modalElementRef]
    )

    return (
        <Modal
            id={PRODUCT_DETAIL_MODAL_ID}
            className={'product-detail-modal'}
            show={show}
            size={'xl'}
            // onExited={handleOnExited}
            onHide={handleOnHide}
            onEntering={handleOnEntered}
        >
            <div className={classNames('product-scroll-buttons')}>
                <div className={'product-scroll-buttons-inner'}>
                    <Button
                        onClick={handlePrevClick}
                        variant={'primary'}
                        className={classNames(`btn-product-detail-scroll`, `btn-product-detail-scroll-prev`, {
                            'd-none': !showScrollPrev,
                        })}
                    >
                        <FlatIcon icon={'chevron-up'} />
                    </Button>
                    <Button
                        onClick={handleNextClick}
                        variant={'primary'}
                        className={classNames(`btn-product-detail-scroll`, `btn-product-detail-scroll-next`, {
                            'd-none': !showScrollNext,
                        })}
                    >
                        <FlatIcon icon={'chevron-down'} />
                    </Button>
                </div>
            </div>
            <Modal.Header>
                <ProductDetailNavigation productId={productId} />
            </Modal.Header>
            <Modal.Body>
                <Product productId={productId} />
                <button type="button" className="close" onClick={handleOnHide}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
            </Modal.Body>

            <Modal.Footer>
                <ProductDetailNavigation productId={productId} />
            </Modal.Footer>
        </Modal>
    )
}

ProductModal.defaultProps = {} as Partial<IProps>

export default ProductModal
